import React, { useState, useEffect } from "react"
import styled from 'styled-components'
import { Link, useStaticQuery, graphql, navigate } from "gatsby"

import Page from "../components/layout/Page"
import PageHeader from "../components/layout/PageHeader"
import SEO from "../components/seo"
import TemplateSearchForm from "../components/forms/TemplateSearchForm"
import Layout2Columns from "../components/layout/Layout2Columns";
import GoogleImage from "../components/GoogleImage"
import { devices } from "../settings"

const LandingPages = styled.div`
  display: flex;
  flex-wrap: wrap;
`
const LandingPageImage = styled.div`
  margin: 5px;
  flex-basis: 32%;

  @media ${devices.mobile} {
    flex-basis: 100%;
  }
`
const AllPagesListing = styled.div`
  margin-top: 50px;
  columns: 5;
  column-gap: 1em;
  box-sizing: border-box;

  > div {
    -webkit-column-break-inside: avoid;
    page-break-inside: avoid;
    break-inside: avoid;
    padding-bottom: 5px;
  }
`

const TemplatesPage = () => {
  const data = useStaticQuery(graphql`
    {
        allMysqlLandingPages {
            edges {
                page: node {
                    id
                    name
                    title
                    keywords
                    featured_ind
                    hidden_ind
                    url
                }
            }
        }
    }
  `);

  //const [search, setSearch] = useState(null);
  const [pages, setPages] = useState(data.allMysqlLandingPages.edges.filter(x => x.page.hidden_ind == 0))
  const featured = data.allMysqlLandingPages.edges.filter(x => x.page.featured_ind == 1 && x.page.hidden_ind == 0);

  /*
  useEffect(
    () => {
      var options = {
        shouldSort: true,
        tokenize: true,
        findAllMatches: false,
        threshold: 0.6,
        location: 0,
        distance: 100,
        maxPatternLength: 32,
        minMatchCharLength: 1,
        keys: [
          "page.title",
          "page.keywords",
        ]
      };

      if (search != null && search.length > 0) {
        const fuse = new Fuse(data.allMysqlLandingPages.edges, options); // "list" is the item array
        const result = fuse.search(search);

        setPages(result);
      }
      else {
        setPages(data.allMysqlLandingPages.edges)
      }
    }, 
    [search]
  );
  */

  function onSearch(keyword) {
    navigate("/templatessearch/?search=" + escape(keyword))
  }

  return (
    <>
      <SEO page="templates" title="Customize a Lit Sign Template" />
      <Page
        breadcrumb={[
          {title: "Home", url:"/"},
          {title: "Sign Templates", url:null}
        ]}
      >
        <Layout2Columns left={60} right={40}>
          <div>
            <PageHeader title="Business Sign Design Templates" subtitle="Choose a template and customize it in our Sign Builder." />
          </div>
          <div style={{textAlign: "right"}}>
              <TemplateSearchForm 
                onSearch={onSearch}
              />
          </div>
        </Layout2Columns>

        <br />

        <LandingPages>
            {featured.map(({page}) => (
              <LandingPageImage key={page.id}>
                  <Link to={"/pages/" + page.name.toLowerCase() + "/"}>
                      <GoogleImage root src={page.url} required lazy alt={page.title} responsive width={345} height={119} />
                  </Link>
              </LandingPageImage>
            ))}
        </LandingPages>

        { pages && 
          <AllPagesListing>
            {pages.map(({page}, index) => (
                <div key={index}>
                  <Link to={"/pages/" + page.name.toLowerCase() + "/"}>
                      {page.title}
                  </Link>
                </div>
              ))}
          </AllPagesListing>
        }
      </Page>
    </>
  )
}

export default TemplatesPage